import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/primary-care/Banner.webp';
import Img1 from '../assets/images/ourservices/primary-care/Img1.webp';
import Img2 from '../assets/images/ourservices/primary-care/Img2.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const PrimaryCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Primary Care - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Primary Care
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Primary Care at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Primary Care <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Primary care physicians specifically are trained for and skilled in comprehensive, first contact, and continuing care for persons with any undiagnosed sign, symptom, or health concern (the “undifferentiated” patient) not limited by problem origin (biological, behavioral, or social), organ system, or diagnosis. Additionally, primary care includes health promotion, disease prevention, health maintenance, counseling, patient education, diagnosis and treatment of acute and chronic illnesses in a variety of health care settings. Primary care is performed and managed by a personal physician who often collaborates with other health professionals, and utilizes consultation or referral as appropriate. Primary care provides patient advocacy in the health care system to accomplish cost-effective and equitable care by coordination of health care services. Primary care promotes effective communication with patients and families to encourage them to be a partner in health care.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
        <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm '>
          Primary Care Team 
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Patients are best served when their care is provided by an integrated practice care team led by a primary care physician. Health professionals work together as an interprofessional, interdependent team in patients' best interests to support comprehensive care delivery. They manage the care of an individual patient and a population of patients using an interprofessional, collaborative approach to health care. The team should support enhanced communication and processes that empower all staff to effectively utilize the skills, training, and abilities of each team member to the full extent of their professional capacity. 
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-md-5">
        <img className='px-md-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            <span className=""> Primary Care Practice </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            A primary care practice serves as the patient's entry point into the health care system and as the continuing focal point for all needed health care services. Primary care practices provide patients with ready access to their own personal physician and health care team.  It includes care that is: Person and family-oriented, continuous, comprehensive and equitable, team-based and collaborative, coordinated and integrated, accessible, and high value.
            <div className="py-2" />
            Primary care practices meet the needs of patients with differentiated and undifferentiated problems and manage the vast majority of patients' concerns. Primary care practices are generally located in the community they serve, thereby facilitating access to health care while maintaining a wide variety of specialty and institutional consultative and referral relationships for specific care needs. The primary care practice structure often includes a team of physicians and other health professionals.

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default PrimaryCare;
