import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/immunization/Banner.webp';
import Img1 from '../assets/images/ourservices/immunization/Img.webp';
import NotesTaking from '../assets/images/aboutus/NotesTaking.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';
import Bullets from '../components/common/Bullets';

const Immunizations = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Immunizations - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
            Immunizations
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Vaccinations at MPC 
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-start">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center'>
            Safeguarding Health, <br className='' /> 
            <span className="text-ss-primary"> One Vaccine at a Time  </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para '>
            Immunizations are crucial in safeguarding individuals and communities against severe and preventable diseases. At Madison Primary Care, we prioritize the health and well-being of our patients by offering a comprehensive vaccination plan.

            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-4 text-start">
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Vaccination Assessment <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Routine Vaccinations Schedule <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Travel Vaccinations Recommendations <br />
            </div>

            <div className="col-11 col-md-4 text-start">
             {/* <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Immune system boosters <br /> */}
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Flu Shots <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Childhood Vaccination Schedule  <br />
            </div>
          
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
        Benefits of <br />
        <span className='text-ss-primary'> Immunizations </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Immunizations offer numerous benefits, including:
        <div className='pt-3'/>
        <Bullets
        Title="Prevention of communicable diseases and their complications."
        />
        <Bullets
        Title="Protection of vulnerable populations, such as infants, elderly individuals, pregnant women, and those with weakened immune systems."
        />
        <Bullets
        Title=" Reduction of healthcare costs associated with treating vaccine-preventable diseases."
        />
        <Bullets
        Title=" Prevention of outbreaks and epidemics within communities."
        />
        </div>

        </div>
        </div>
        <div className="col-6 col-lg-5 text-center px-md-5">
        <img className='px-md-3 w-75' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Protect Yourself and  <br className='' /> 
            <span className="text-ss-primary"> Your Loved Ones with Immunizations </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Vaccinations are a vital part of preventive healthcare. Visit Madison Primary Care today to ensure you and your family are fully protected against vaccine-preventable diseases. Your health is our priority, and we're here to help you stay healthy and safe.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="Are vaccines safe? "
          Para="Vaccines are rigorously tested for safety and effectiveness before being approved for use. The benefits of vaccines far outweigh the risks. "
          />

          {/* <FAQs
          Title="Do immunity shots cause autism? "
          Para="No, numerous studies have shown no link between immunity shots and autism. "
          /> */}

          <FAQs
          Title="Can I get vaccinated if I'm pregnant? "
          Para="Some vaccines are safe to receive during pregnancy, while others are not recommended. It's best to consult with your healthcare provider for personalized recommendations. "
          />

          {/* <FAQs
          Title="Are there any side effects of immunity system boosters? "
          Para="Immunity system boosters can cause side effects like any medication but are mild and temporary. Serious side effects are rare. "
          /> */}

          <FAQs
          Title="Can vaccines be given if I have a cold or mild illness? "
          Para="In most cases, it's safe to receive vaccines if you have a cold or mild illness. However, if you have a fever or more severe symptoms, postponing vaccination until you feel better is best. "
          />

          <FAQs
          Title="How long does vaccine protection last? "
          Para="The duration of vaccine protection varies depending on the vaccine and individual factors. Some vaccines provide lifelong immunity, while others require booster doses to maintain immunity over time. "
          />

          <FAQs
          Title="Are vaccines necessary if diseases are rare? "
          Para="Vaccines are still necessary even if certain diseases are rare because they help prevent outbreaks and protect individuals who may be at risk due to factors such as age or underlying health conditions. "
          />

        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default Immunizations;
