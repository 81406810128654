import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
 
const FAQs = (props) => {
  return (
    <>
                    <Accordion className='border-0 bg-lightblue2 '>
                      <Accordion.Item eventKey="0">
                          <Accordion.Header>
                              <span className='faqs-heading text-ss-secondary py-2 fw-600 fs-ss-18 '>
                                {props.Title}
                              </span>
                          </Accordion.Header>
                          <Accordion.Body className='text-start bg-lightblue'>
                              <span className='fs-ss-16'>
                                {props.Para}
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    
      </>
  );
}

export default FAQs;
