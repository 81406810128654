import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/chronic-disease-management/Banner.webp';
import Img1 from '../assets/images/ourservices/primary-care/Img2.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const ChronicDiseaseManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Chronic Disease Management - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Chronic Disease Management
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Chronic Disease Management at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 lh-sm text-center'>
    Chronic Disease Management
    </div>
    <div className='pt-3'/>
    <div className='fs-ss-16 fw-400 lh-para'>
    The defining features of primary care is continuity, coordination, and comprehensiveness which are well suited to care of chronic illness. 
    <div className="py-2" />
    A focal point in Primary Care is helping patients manage their chronic diseases to maintain and help improve their quality of life. At the same time, your Primary Care provider seeks to prevent or minimize the effects of chronic conditions. Through regularly scheduled visits, you and your Provider should remain connected, improving the coordination of care across specialties and healthcare delivery points. capacity. 
    </div>
    </div>
    </div>
  </div>
  </section>

  <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-evenly gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <div className='fs-ss-35 fw-700 lh-sm '>
        Why Invest in Chronic Disease Management Services
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Opting for chronic disease management services is a crucial step toward reclaiming control over your health and enhancing your overall well-being. For individuals grappling with chronic conditions, these services provide a structured support system that promotes better understanding and management of their illnesses. By participating in tailored programs, patients gain access to expert guidance, educational resources, and personalized care plans that are designed to meet their unique needs.
        <div className="py-2" />
        Moreover, these services foster proactive health monitoring, helping to prevent complications and hospitalizations. Patients benefit from a collaborative approach, where healthcare providers work closely with them to set achievable goals and track progress. This not only boosts motivation but also cultivates a sense of community among peers facing similar challenges. Ultimately, investing in chronic disease management services empowers individuals to lead healthier, more fulfilling lives, reducing stress and improving quality of life in the long run.

        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-md-5">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default ChronicDiseaseManagement;
