import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/accepted-insurances/Banner.webp';
import Img1 from '../assets/images/accepted-insurances/Img1.webp';
import Icon1 from '../assets/images/accepted-insurances/Icon1.webp';
import Icon2 from '../assets/images/accepted-insurances/Icon2.webp';
import {Link} from "react-router-dom";
import MiddleBanner from '../components/common/MiddleBanner';
import Bullets from '../components/common/Bullets';
import Insurances from '../components/Insurances';

const AcceptedInsurances = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Accepted Insurances - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-md-5 py-5 my-3 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Accepted Insurances
          </div>
          {/* <div className='fw-400 lh-sm text-ss-green fs-ss-22 col-md-10 mx-auto'>
          </div> */}
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm pb-2 text-center'>
    Insurances
    </div>
    <div className='fs-ss-16 fw-400 lh-para text-center pb-4'>
      We accept or are in-network with the following Primary Payer Insurances:
      </div>
      <div className="row justify-content-center">
        <div className="col-11">
          <div className=''>
            <div className="row justify-content-start fs-ss-16 fw-500 lh-para">
              <Insurances/>
            </div>

            <div className='fs-ss-22 fw-600 lh-para pt-5 pb-4 text-center'>
            We Also Accept
            </div>
            <div className="row justify-content-center pt-3 fs-ss-16 fw-500 lh-para gy-4">
              <div className="col-10 col-md-3 text-center shadow-ss-1 p-4 bg-ss-primary-light rounded-4 mx-md-4">
                <img src={Icon1} className='w-50 px-4' alt="" />
               <div className="pt-4 fw-600 fs-ss-22">
               Self-Pay <br className="d-none-d-md-block" /> Patients
              </div> 
              <div className="pt-2 pt-md-4 fs-ss-16">
              (No Insurance)
              </div>
              </div>
              <div className="col-10 col-md-3 text-center shadow-ss-1 p-4 bg-ss-primary-light rounded-4 mx-md-4">
              <img src={Icon2} className='w-50 px-4' alt="" />
               <div className="pt-4 fw-600 fs-ss-22">
               Pre-Payment <br className="d-none-d-md-block" /> Patients
              </div> 
              <div className="pt-2 pt-md-4 fs-ss-16">
              (Prepay for your office visits, and file your own insurance claim)
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Self-Pay Patients
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            If you do not have insurance, you may have the option to become a Self-Pay patient. Our Self-Pay and Pre-Payment Fee Schedule for our most common services is listed on our website so you are aware of your financial obligations in advance. A card on file is required for all Pre-payment Patients.  
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
          <div className='pt-2'/>
          <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
          Pre-Payment Patients
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        If we are not in-network, or accepting your insurance package, you may have the option to become a Prepayment patient and submit your own claims to your insurance for reimbursement consideration. Unfortunately, we can not guarantee what amount or if your insurance company will reimburse you. Our Self-Pay and Pre-Payment Fee Schedule for our most common services is listed on our website at so you are aware of your financial obligations in advance.  A card on file is required for all Pre-Payment Patients.  
        </div>

        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-md-5">
        <img className='px-md-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Financial Responsibility
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            With the wide variability in insurance policies (even within the same companies), it is not possible for us to be aware of how your insurance company determines which medical services or labs will be paid. Our responsibility to our patients is to provide high-quality, evidence-based medical care, on an individualized basis. Please familiarize yourself with your benefits, as they can change. You are ultimately responsible for all services rendered by our office, or external facilities. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Patient Relationship
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            We respectfully remind our patients that our relationship is with them, not their insurance. Patients are responsible for any out-of-network or non-covered services. We encourage patients to be familiar with and comply with all their payer guidelines to maximize benefits. A card on file will be required. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Primary Care Provider (PCP) Assignment
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Some payers require a PCP assignment to cover any services from your doctor or provider, as well as any referrals from your PCP. Please contact your insurance before your first appointment and select Dr. Bodla as your PCP if needed, and also verify coverage with Madison Primary Care. Insurance claims will be filed for you as a courtesy for in-network payers. If your insurance deems your services as non-covered, you will be responsible for the balance. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Insurance Referrals
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            A visit is required for any new referral. Some insurances require an insurance referral, along with a doctor-to-doctor referral. Failure to obtain this insurance referral from your insurance may render your specialty visit a 100% out-of-pocket expense. You must request and notify our office that an insurance referral is needed whenever indicated. Patients must Independently verify their eligibility and coverage with each doctor they visit. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Payment
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Co-pays, deductibles, and balances will be collected before each office visit. A $100 deposit will be collected towards each visit for high-deductible plans with unmet patient responsibility. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default AcceptedInsurances;
