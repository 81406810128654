import React, { Component, useState } from "react";
import Slider from "react-slick";
import { StarFill } from "react-bootstrap-icons";


const Testimonials = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
    ]
  };

  const Data = [
    // {
    //   title: "Vince Lawson",
    //   link: "https://g.co/kgs/6HMRQQu" ,
    //   para: "Dr. Bodla and his team are fantastic.  They always take the time to listen to my concerns and address them.  Dr. Bodla has been my primary care provider for over 10 years.  I would highly recommend Madison Primary Care to anyone that needs a family physician.  Special shout out to Tanya Pike, she is awesome.",
    //   star: <StarFill className="color-star" />
    // },

    {
      title: "Cathy Jerrell-Johnson",
      link: "https://g.co/kgs/uy8U3uv" ,
      para: "Great staff who really care about you. The waiting is always very minimal. We love Dr. KATY KOSHEFER. We followed her from a previous practice to this business. She really takes her time with you and deals with senior issues very well. She's very patient with us. The building is spotless always.",
            star: <StarFill className="color-star" />
    },

    {
      title: "Guy Boyd",
      link: "https://g.co/kgs/KHnJh2e" ,
      para: "I have been a patience of Dr Bodla for many years. I have always been treated well and taken care of by the team at Madison Primary Care. Dr Bodla's #1 priority is my well-being as is that of all of the patience's. I feel very fortunate to have found this wonderful practice.",
            star: <StarFill className="color-star" />
    },

    {
      title: "M Dohr",
      link: "https://g.co/kgs/rTGfseP" ,
      para: "Excellent primary care practice in Madison Al.  The office is clean, modern and well decorated. The practitioner s are efficient, amicable and thorough. Ample parking with labs on site.",
            star: <StarFill className="color-star" />
    },

    {
      title: "Aliyah Rashid",
      link: "https://g.co/kgs/pcJDKsZ" ,
      para: "Madison Primary Care truly cares about each and every one of their patients! they make sure to make you their priority and go out of their way to fulfill your needs. they’re organized and efficient when helping you. the clean and comfy facility is also a plus! shout out to Melissa.",
            star: <StarFill className="color-star" />
    }
  ]

  return (
    <>
        <section className="container-fluid">
        <Slider {...settings}>
    {
      Data.map((Data) => {
        return (
        <>
          <div className="col-11 bg-grey2 shadow-ss-1 my-4 my-lg-5 p-4 rounded-5 vh-max-content">
          <a className="text-decoration-none text-ss-secondary hover-pri" href={Data.link} target="_blank">
            <div className="fs-ss-22 fw-600">
              {Data.title}
            </div>
            <div className="py-2 text-ss-secondary fs-ss-13 fw-500">
            {Data.para}
            </div>
            <div className="">
            <StarFill className="color-star me-lg-2" /> 
            <StarFill className="color-star me-lg-2" /> 
            <StarFill className="color-star me-lg-2" /> 
            <StarFill className="color-star me-lg-2" /> 
            {Data.star}
            </div>
            </a>
          </div>
        </>
        )
      })
    }
            </Slider>
      </section>
      </>
    );
  }

export default Testimonials;
