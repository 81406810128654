import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/laboratory-services/Banner.webp';
import Img1 from '../assets/images/ourservices/laboratory-services/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const Laboratory = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Laboratory Services - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Laboratory Services 
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Lab Testing Services at MPC 
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Laboratory Services <br className='' /> 
            <span className="text-ss-primary">  Madison Primary Care  </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            In healthcare, accurate and timely diagnostic tests are the cornerstone of effective treatment and management. At Madison Primary Care, we recognize the significance of laboratory services in providing crucial information for informed health decisions.
            </div>
            {/* <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-4 text-start">
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Comprehensive Testing  <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Timely Results   <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Accuracy and Precision  <br />
            </div>

            <div className="col-11 col-md-4 text-start">
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Advanced Technology  <br />
             <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Collaborative Approach  <br />
            </div>
            
            </div> */}
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
        Laboratory services offer <br />
        <span className='text-ss-primary'> numerous benefits, including: </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Early detection of health conditions <br />
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Monitoring of disease progression <br />
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Evaluation of treatment effectiveness <br />
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Facilitation of preventive care measures <br />
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Provision of data for personalized health plans <br /> 
        </div>
        </div>
        </div>
        <div className="col-7 col-lg-5 text-center px-md-5">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Get Accurate and <br className='' /> 
            <span className="text-ss-primary"> Timely Test Results Today </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Don't leave your health to chance. Trust the experts at Madison Primary Care for all your laboratory testing needs. With our state-of-the-art facilities and experienced laboratory team, you can rest assured that you're safe. Schedule your laboratory test with us today and take control of your health. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="Are laboratory tests accurate? "
          Para="Yes, laboratory tests are highly accurate when performed by skilled technicians using advanced technology. However, it's essential to interpret the results in conjunction with clinical findings for a precise diagnosis."
          />

          <FAQs
          Title="How long do laboratory test results take? "
          Para="The turnaround time for laboratory test results varies depending on the type of test and laboratory workload. Routine tests may be available within a few hours to a few days. "
          />

          <FAQs
          Title="Do I need to fast before specific laboratory tests? "
          Para="Some tests, such as lipid panels or glucose tests, may require fasting beforehand to obtain accurate results. Our healthcare provider will provide specific instructions if fasting is necessary. "
          />

          <FAQs
          Title="Can I request specific laboratory tests? "
          Para="You can discuss your concerns and preferences with our healthcare provider, who can order specific laboratory tests based on your symptoms, medical history, and health goals. "
          />

          <FAQs
          Title="What happens if my laboratory test results are abnormal? "
          Para="If your laboratory test results are abnormal, your healthcare provider will interpret the findings in the context of your overall health and may recommend further diagnostic tests or treatment options. Routine laboratory results are not discussed by phone, rather during office visits. Your follow-up visit is typically scheduled and coordinated with your routine laboratory tests."
          />

          <FAQs
          Title="Does insurance cover laboratory tests? "
          Para="In many cases, laboratory tests are covered by health insurance plans, especially when ordered by a healthcare provider for diagnostic or preventive purposes. At times insurances differ in regards to what your Provider recommends, and what they feel is medical necessity. It is advisable to check with your insurance provider regarding coverage details. With the wide variability in insurance policies, we can't be aware of how your insurance company determines which medical services/labs will be paid. Some pay only for illness, some only for wellness visits, and some do not pay for ANY services till high deductibles are met. Our responsibility to our patients is to provide quality, evidence-based medical care, on an individualized basis. Please familiarize yourself with your benefits, as they can change. You are ultimately responsible for all services rendered by our office, or external facilities. You may obtain services at any external lab of your choice."
          />

          {/* <FAQs
          Title="Can I access my laboratory test results online? "
          Para="Many healthcare facilities offer online portals where patients can securely access their laboratory test results. You can search for “lab services near me” or contact our office to access your results through our patient portal. "
          />
           */}
          <FAQs
          Title="Questions regarding your Laboratory bill?"
          Para="Laboratories are separate entities, and they will bill you or your insurance company. If you have any questions about your laboratory bill, please contact the lab or your insurance company with questions. At MPC, laboratory services are provided by an Independent Lab. This means the laboratory work is billed entirely by those independent companies. The laboratory services are offered as a convenience to our patients, you are responsible for any insurance restrictions, and visiting the appropriate lab for each blood draw."
          />
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default Laboratory;
