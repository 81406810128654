import React, { Component, useState } from "react";
import Slider from "react-slick";
import Img1 from "../assets/images/accepted-insurances/1.webp";
import Img2 from "../assets/images/accepted-insurances/2.webp";
import Img3 from "../assets/images/accepted-insurances/3.webp";
import Img4 from "../assets/images/accepted-insurances/4.webp";
import Img5 from "../assets/images/accepted-insurances/5.webp";
import Img6 from "../assets/images/accepted-insurances/6.webp";


const Insurances = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      },
    ]
  };

  const Data = [

    {
      Img: Img1
    },

    {
      Img: Img2
    },

    {
      Img: Img3
    },

    {
      Img: Img4
    },

    {
      Img: Img5
    },

    {
      Img: Img6
    },

  ]

  return (
    <>
        <section className="container-fluid">
        <Slider {...settings}>
    {
      Data.map((Data) => {
        return (
        <>
          <div className="mx-auto col-7">
            <img src={Data.Img} alt="" className="w-100" />
          </div>
        </>
        )
      })
    }
            </Slider>
      </section>
      </>
    );
  }

export default Insurances;
