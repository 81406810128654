import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/aboutus/Banner.webp';
import Stethoscope from '../assets/images/aboutus/stethoscope.webp';
import IconPlus from '../assets/images/aboutus/firstaid.webp';
import NotesTaking from '../assets/images/aboutus/NotesTaking.webp';
import {Link} from "react-router-dom";
import Icon1 from '../assets/images/aboutus/Icon1.webp';
import Icon2 from '../assets/images/aboutus/Icon2.webp';
import Icon3 from '../assets/images/aboutus/Icon3.webp';
import Icon4 from '../assets/images/aboutus/Icon4.webp';
import Icon5 from '../assets/images/aboutus/Icon5.webp';
import Icon6 from '../assets/images/aboutus/Icon6.webp';
import Icon7 from '../assets/images/aboutus/Icon7.webp';
import Icon8 from '../assets/images/aboutus/Icon8.webp';
import Icon9 from '../assets/images/aboutus/Icon9.webp';
import Icon10 from '../assets/images/aboutus/Icon10.webp';
import HeartLogo from '../assets/images/aboutus/heart.webp';
import { Player } from 'video-react';
import VideoMadisonPrimaryCare from '../assets/images/aboutus/video.mov';
import { TelephoneFill } from 'react-bootstrap-icons';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      About Us - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-md-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
           About Us
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Madison Primary Care
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center">
        <div className="col-11 col-md-11">
          <div className=''>
            <div className='fs-ss-16 fw-400 lh-para'>
            Welcome to Madison Primary Care, established with the goal of providing compassionate healthcare for our community. Established in April 2011, we're your dedicated partners in wellness, committed to delivering exceptional quality care for our patients.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary text-center " style={{borderRadius:"40px"}}>
      <div className="tex-center">
        <div className="">
          <div className='col-11 mx-auto mb-md-5'>
          <img className='w-icon' src={IconPlus} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
              Our Core Values
            </div>
            <div className='pt-2'/>
            <div className='fs-ss-16 fw-400 lh-para col-md-8 text-center mx-auto'>
            Our primary goal in healthcare is to value and respect everyone. We make sure their dignity, well-being, and permission are priorities. 
            </div>
          </div>
        </div>

        <div className="row justify-content-center text-center gy-4 mt-4">
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon1} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Trust
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon2} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Quality
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon3} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Dignity
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon4} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Healing
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon5} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Honesty
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon6} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Excellence
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon7} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Respect
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon8} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Compassion 
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon9} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Integrity
            </div>
          </div>
          <div className='col-4 col-md-2 mx-md-1'>
          <img className='w-75 px-md-2' src={Icon10} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-18 fw-600'>
            Relationships
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5'/>
    
    <section id='website-responsive' className="container-fluid bg-grey-2 py-5">
    <section className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-md-11">
          <div id='mission' className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
              Mission
              </div>
              <div className='pt-3'/>
              <div className='fs-ss-16 fw-400 lh-para text-start'>
                Our mission at Madison Primary Care is to provide our patients with the highest quality healthcare, within the scope of our specialty - Family Medicine. Our goal is to improve the health and well-being of our patients and the communities we serve with a commitment to compassion and excellence in all that we do. 	 
                </div>
                </div>
                </div>
                </div>
                </section>
               </section> 
               
                <section className='pt-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center ">
        <div className="col-11 col-md-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
              Vision
              </div>
              <div className='pt-3'/>
              <div className='fs-ss-16 fw-400 lh-para'>
                As specialists in Primary Care, we seek to provide high-quality comprehensive health care for each patient we serve. We strive to partner with each patient to provide personalized care while recognizing diversity in individual needs. The Madison Primary Care Team will transform the healthcare experience through a culture of extraordinary care and exceptional service, resulting in unparalleled patient satisfaction.  
                </div>
                </div>
                </div>
                </div>
                </section>

        <section className='pt-5'/>

        <section className="container-fluid px-md-0">
        <section id='website-responsive' className="container-fluid text-ss-secondary bg-video py-md-5">
          <div className="row justify-content-center text-center">
            <div className="col-md-7 bg-grey-2 px-2 pt-2 rounded-3 shadow-ss-1 btn-black">
              <video className='w-100 rounded-3' src={VideoMadisonPrimaryCare} autoPlay={true} loop="true" muted controls />
            </div>
            </div>
        </section>

        <section className='pt-5'/>
        
      <section id='website-responsive' className="container-lg text-center text-md-start text-ss-secondary">
      <div className="row justify-content-center gy-5 align-items-center">
        <div className="col-8 col-md-5 text-center px-md-5 d-none d-md-block">
        <img className='w-100' src={NotesTaking} alt="" />
        </div>

        <div className="col-12 col-md-6">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
          Your Journey to Wellness
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        At Madison Primary Care, we invite you to experience healthcare designed around you. Take the first step towards quality healthcare with superior customer service by scheduling your first visit. Let us become your health partners, collaborating towards long-term wellness and relationships.
        </div>
        <div className='pt-4'/>
        <div className='pt-2'/>
        <div className=''>
            <a href="tel:+12567745524" className='text-decoration-none py-2 fw-500 btn-ss-primary border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
             <TelephoneFill className='me-1' /> 256-774-5524
              </a>
          </div>
        </div>
        </div>
        <div className="col-8 col-md-5 text-center px-md-5 d-block d-md-none">
        <img className='w-100' src={NotesTaking} alt="" />
        </div>
      </div>
      </section>

      <section className='pt-4 pt-md-5'/>
      </section>

    <Footer/>
    </>
  );
}

export default AboutUs;
