import React from 'react';
import { Link } from "react-router-dom"
import { Link as LinkScroll} from "react-scroll"
import Navbar from 'react-bootstrap/Navbar';
import HeaderLogo from '../../assets/images/home/HeaderLogo.webp';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavBarLink from './NavBarLink';
import NavBarLink2 from './NavBarLink2';
import PDF from '../../assets/images/home/FeeSchedule.pdf';
import { useLocation } from "react-router-dom";
import NavBarLink3 from './NavBarLink3';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
    <section className="container-fluid px-lg-4">
      <Navbar expand="lg" className="">
        <div className='container-fluid gx-0'>
            <Navbar.Brand className='navbar-brand ms-2 py-2'>      
              <Link
            className=""
            to="/"
            >
            <img
                src={HeaderLogo}
                alt="Logo"
                className="header-logo"
            />
            </Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} className='border-0 rounded-0 toggle-icon'/>

            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton className=''>

                <Offcanvas.Title className='' id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className=""
                    to="/"
                    >
                    <img
                        src={HeaderLogo}
                        alt="Logo"
                        className="w-50"
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <ul className="navbar-nav ms-auto">

            <NavBarLink
              Name="About Us"
              Link_To="about-us"
              />
{/*               
            <NavBarLink2
              Name="About Us"
              Link_To="about-us"
              DropdownMenu={<>
              <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
                <a className={splitLocation[1] === `/about-us/#mission` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="/about-us#mission"> Mission </a>
              </li>
              </>}
              />  */}
              
              <NavBarLink
              Name="Clinical Team"
              Link_To="clinical-team"
              />

              <NavBarLink2
              Name="Services"
              Link_To="services"
              DropdownMenu={
                <>

          <NavBarLink
          Name="Primary Care"
          Link_To="primary-care"
          Border="border-grey border-bottom border-1 fw-400"
          />     

          <NavBarLink
          Name="Preventive Care"
          Link_To="preventive-care"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Chronic Disease Management "
          Link_To="chronic-disease-management"
          Border="border-grey border-bottom border-1 fw-400"
          />          

          <NavBarLink
          Name="Sick Visits & Urgent Care"
          Link_To="sick-visits-and-urgent-care"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Diabetes Care"
          Link_To="diabetes-care"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Medicare CCM Services"
          Link_To="medicare-ccm"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Minor Skin Procedures "
          Link_To="minor-skin-surgeries"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Weight Loss Management"
          Link_To="weight-loss-management"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Pediatric Care"
          Link_To="pediatric-care"
          Border="border-grey border-bottom border-1 fw-400"
          />  
          
          <NavBarLink
          Name="Laboratory Services"
          Link_To="laboratory-services"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="Immunizations"
          Link_To="immunizations"
          Border="border-grey border-bottom border-1 fw-400"
          />

          <NavBarLink
          Name="EKG Services "
          Link_To="ekg"
          Border="border-grey border-bottom border-1 fw-400"
          />
          </>
          }              
          />                 
          
          <NavBarLink3
              Name="Medicare Patients"
              Link_To="medicare-patients"
              DropdownMenu={
                <>

              <NavBarLink
              Name="Chronic Care Management"
              Link_To="medicare-ccm"
              Border="border-grey border-bottom border-1 fw-400"
              />

              <NavBarLink
              Name="Accountable Care Organization "
              Link_To="medicare-aco "
              Border="border-grey border-bottom border-1 fw-400"
              />              
              </>
              }
              /> 
                        
              <NavBarLink
              Name="Contact Us"
              Link_To="contact-us"
              />
              
              
              <NavBarLink3
              Name="Billing & Insurance"
              Link_To="billing-and-insurance"
              DropdownMenu={
                <>

              <NavBarLink
              Name="Accepted Insurances"
              Link_To="accepted-insurances"
              Border="border-grey border-bottom border-1 fw-400"
              />
              
              <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
                <a className="border-grey border-bottom border-1 fw-400 nav-link hover-green text-ss-secondary" href={PDF} target='_blank' > MPC Fee Schedule </a>
              </li>                       

              </>
              }
              /> 
            

            <li className="nav-item fw-500 fs-ss-links right-menu d-block d-lg-none">
          <div class="btn-group dropdown">
          <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green` : `nav-link hover-green text-ss-secondary`} href="https://12216.portal.athenahealth.com/" target="_blank" > Patient Portal </a>
            <a class="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-black" data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span class="visually-hidden">Toggle Dropdown</span>
              </a>
          </div>
          <ul className="dropdown-menu hover1 fs-ss-links w-content">

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Send Messages </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Make Appointments </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Review Health Records </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Pay Invoices </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Refill Requests </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Forms </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Billing Questions </a>
          </li> 

          </ul>
    </li>
    
    <li className="nav-item fw-500 fs-ss-links right-menu d-none d-lg-block">
          <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link dropdown-toggle nav-link hover-green text-ss-green ` : `nav-link dropdown-toggle hover-green text-ss-secondary `} href="https://12216.portal.athenahealth.com/" target="_blank"> Patient Portal </a>
          <ul className="dropdown-menu hover1 fs-ss-links w-content">

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Send Messages </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Make Appointments </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Review Health Records </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Pay Invoices </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Refill Requests </a>
          </li> 
          
          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Forms </a>
          </li>

          <li className="nav-item fw-500 fs-ss-links text-ss-secondary">
            <a className={splitLocation[1] === `/PATIENT-PORTAL` ? `nav-link hover-green text-ss-green border-grey border-bottom border-1 fw-400` : `nav-link hover-green text-ss-secondary border-grey border-bottom border-1 fw-400`} href="https://12216.portal.athenahealth.com/" target="_blank"> Billing Questions </a>
          </li> 

          </ul>
    </li>
              <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='ms-lg-2 mt-4 mt-lg-0 text-center nav-link fw-500 shadow-ss-1 btn-black border-0 rounded-3 border-0 text-white fs-ss-links px-3 bg-hover-green'>
                New Patients
              </a>
              <span className='mx-1 pt-3' />
              <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-center nav-link fw-500 btn-black shadow-ss-1 border-0 rounded-3 border-0 text-white fs-ss-links px-3 bg-hover-green'>
                Current Patients
              </a>


            </ul>

            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
    </section>
    </>
  )
}

export default Header