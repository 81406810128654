import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/preventive-care/Banner.webp';
import Img1 from '../assets/images/ourservices/preventive-care/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const PreventiveCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Preventive Care - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Preventive Care
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Preventive Care at MPC
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Preventive Care <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Preventive measures are vital to preserving good health and preventing chronic disease. At Madison Primary Care, we prioritize Preventative Care and Health Care Maintenance screenings, and proactive strategies to achieve optimal health. Partner with our healthcare professionals to develop a personalized care plan grounded in evidence based medicine, based on age and risk factors.
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-5 text-start">
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Comprehensive Health Assessments <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Health Screenings <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Lifestyle Counseling <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Vaccinations <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Annual Physical Exams <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Cancer Prevention Screenings <br/>
            </div>

            <div className="col-11 col-md-4 text-start">
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Fall Risk Assessments <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Depressions Screenings <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Developmental Assessments <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Behavioral Screenings  <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Chronic Disease Management <br/>
            </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-35 fw-700 lh-sm '>
        Benefits of  <br />
        <span className='text-ss-primary'> Preventive Care </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        Preventive care service is essential for maintaining optimal health and well-being. By being proactive with regular check-ups and screenings, individuals can detect potential health issues early, allowing timely intervention and treatment. Preventive care also empowers individuals to make informed lifestyle choices that can reduce their risk of developing chronic conditions in the future. From vaccinations to cancer screenings, prioritizing preventive care can promote a healthier and happier life. 
        </div>
        </div>
        </div>
        <div className="col-8 col-lg-5 text-center px-md-5">
        <img className='px-md-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Invest in Your <br className='' /> 
            <span className="text-ss-primary"> Health with Preventive Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Preventive care is more than just routine check-ups; it's a proactive approach to health that focuses on identifying potential health risks before they escalate into serious issues. Our comprehensive services encompass regular health assessments, screenings for early detection of diseases, personalized lifestyle counseling to promote healthy habits, vaccinations to prevent illness, and effective management of chronic conditions. By investing in preventive health care, you invest in your future well-being and quality of life. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <div className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="How often should I undergo preventive care assessments? "
          Para="The frequency of preventive care assessments may vary depending on age, gender, medical history, and risk factors. In general, adults should have an annual wellness visit with their healthcare provider to review overall health and discuss any preventive screenings or interventions that may be recommended. "
          />

          <FAQs
          Title="What health screenings are included in preventive care?  "
          Para="Health screenings may consist of blood pressure checks, cholesterol screenings, blood glucose tests, cancer screenings (such as mammograms, Pap smears, and colonoscopies), and screenings for conditions like osteoporosis and vision problems. The screenings recommended for you will depend on your age, gender, and individual risk factors. "
          />

          <FAQs
          Title="What role does lifestyle counseling have in preventative medicine? "
          Para="Lifestyle counseling encourages healthy habits and behaviors that can prevent or manage chronic diseases. These include eating a balanced diet, having a healthy body and mind, exercising frequently, managing stress, abstaining from tobacco and excessive alcohol use, and getting enough sleep. Adopting healthier lifestyle choices can enhance your general well-being and lower your risk of developing several health issues. "
          />

          <FAQs
          Title="Are vaccinations necessary for adults? "
          Para="Yes, vaccinations are an essential component of preventive care for adults. Vaccinations can help protect against infectious diseases such as influenza, pneumonia, tetanus, and shingles, reducing complications and the risk of illness. Your healthcare provider can recommend specific vaccinations based on age, medical history, and lifestyle."
          />

          <FAQs
          Title="Can preventive care help detect health issues early? "
          Para="Yes, preventive care aims to detect health issues in their earliest stages when they are most treatable. Regular screenings and health assessments allow healthcare providers to identify risk factors, monitor changes in health status, and intervene promptly to prevent or manage potential health problems. "
          />

          <FAQs
          Title="How can I schedule a preventive care appointment at Madison Primary Care? "
          Para="Scheduling a preventive care appointment at Madison Primary Care is easy. Call our office or use our online appointment scheduling system to book a convenient time for your visit. Our friendly staff will work with you to accommodate your needs and ensure you receive the required preventive care services. "
          />


        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default PreventiveCare;
