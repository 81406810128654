import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/team/Banner.webp';
import Hospital from '../assets/images/home/Hospital.webp';
import Icon from '../assets/images/team/Icon.webp';
import SingleService from '../components/common/SingleService';
import Icon1 from '../assets/images/home/Diabetes.webp';
import Icon2 from '../assets/images/home/Immunizations.webp';
import Icon3 from '../assets/images/home/Laboratory.webp';
import Icon4 from '../assets/images/home/Surgery.webp';
import Icon5 from '../assets/images/home/Preventative.webp';
import Icon6 from '../assets/images/home/Weight.webp';
import Icon7 from '../assets/images/home/EKG.webp';
import Icon8 from '../assets/images/home/Urgent.webp';
import Icon9 from '../assets/images/home/Education.webp';
import Icon10 from '../assets/images/home/Pediatric.webp';
import Img1 from '../assets/images/team/5.webp';
import Img2 from '../assets/images/team/3.webp';
import Img3 from '../assets/images/team/7.webp';
import Img4 from '../assets/images/team/2.webp';
import Img5 from '../assets/images/team/4.webp';
import Img6 from '../assets/images/team/1.webp';
import Img7 from '../assets/images/team/7.webp';
import Img8 from '../assets/images/team/8.webp';
import Img9 from '../assets/images/team/7.webp';
import Img10 from '../assets/images/team/7.webp';
import Img11 from '../assets/images/team/11.webp';
import Img12 from '../assets/images/team/12.webp';
import Img13 from '../assets/images/team/13.webp';
import Img14 from '../assets/images/team/14.webp';
import { Link } from 'react-router-dom';
import OneClinicalTeam from '../components/OneClinicalTeam';

const ClinicalTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Clinical Team - Madison Primary Care
      </title>
    </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Clinical Team
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Expertise, Empathy, Excellence 
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary px-md-5">
          <div className='text-center'>
          <img className='w-logo' src={Icon} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm '>
            Meet our Clinical Team <br className='' /> 
            <span className="text-ss-primary"> MPC Providers </span>
            </div>
          </div>
          <div className="row justify-content-center align-items-center gy-4 pt-lg-5 pt-4 px-lg-5">

          <OneClinicalTeam
          Title1="Adeel Bodla"
          Title2="- MD"
          Title3="Board Certified"
          Title4={
            <>
            <div className="">
              Board Certified, Family Medicine <br />
              Established MPC in 2011 
            </div>
            </>
          }
          BackDrop1="#staticBackdrop"
          BackDrop2="staticBackdrop"
          ProfileImg={Img1}
          Para={<>

          Dr. Bodla is board-certified and completed his internship in Family Medicine at the Charles Drew University of Medicine and Science in Los Angeles, California; and his residency at the University of Alabama Birmingham, in Huntsville. 
          <div className="py-2" />
          After graduation in 2009, Dr. Bodla served as an Assistant Professor at the University of California-Irvine. Here he enjoyed working in the University Family Medicine Clinic, as a Hospitalist on the in-patient side, as well as serving as an attending physician for the Department of Family Medicine Residency program. 
          <div className="py-2" />
          Dr. Bodla worked for a period as a Hospitalist providing inpatient care but decided to pursue his dream of opening a Primary Care practice, and established Madison Primary Care in 2011 
          <div className="py-2" />
          Dr. Bodla strongly believes in forming close and lasting relationships with his patients to positively impact their health and wellness. In his free time, Dr. Bodla enjoys spending time with his wife and children. Recreationally he enjoys playing tennis, golf, badminton, and watching all sporting events. Dr. Bodla looks forward to serving your Family Medicine needs. 
          <div className="py-2" />
          Dr. Bodla serves as the Family Medicine Division Chair for the Department of Medical Services at Crestwood Medical Center and is also a volunteer for the Avicenna Free Medical Clinic. 
          </>} 
          />

          <OneClinicalTeam
          Title1="Lindsey Guill"
          Title2="- PA"
          Title3="Board Certified"
          Title4={<>
          <div className="">
          Board Certified, NCCPA <br />
          Joined MPC in 2022 
          </div>
          </>}
          BackDrop1="#staticBackdrop1"
          BackDrop2="staticBackdrop1"
          ProfileImg={Img2}
          Para={<>

          Lindsey Guill was born and raised in Tennessee. She attended Harding University, where she received a Bachelor of Science Degree in Molecular and Cellular Biology. During her time in college, she lived in Zambia, Africa where she fell in love with helping people through practicing medicine. While in Zambia Lindsey completed a minor in Medical Missions. She went on to receive a Master of Science degree in Physician Assistant Studies from Harding University, is certified by the NCCPA, and is licensed by the ALBME. Lindsey has been practicing Family Medicine in Madison, AL for years, and considers it an honor to care for her patients. Lindsey is happily married to her college sweetheart, and they enjoy traveling, hiking, playing tennis, and spending time with friends and family. Lindsey welcomed her first baby into the world in 2024, and is thrilled to be a new mother.  
          </>}         
          />

          <OneClinicalTeam
          Title1="Alexandra Maner"
          Title2="- PA"
          Title3="Board Certified"
          Title4={
            <>
          <div className="">
          Board Certified, NCCPA <br />
          Joined MPC in 2023 
          </div>             
            </>
          }
          BackDrop1="#staticBackdrop2"
          BackDrop2="staticBackdrop2" 
          ProfileImg={Img8}
          Para={<>
                
          Alex Maner was born and raised in North Alabama. After graduating from Hartselle High School, she attended Auburn University, where she received a Bachelor of Science in Microbiology and a Minor in Hunger Studies. Alex spent a year working at a family practice in Madison and fell in love with primary care. She then attended the University of Alabama at Birmingham and received a Master of Science in Physician Assistant Studies. Alex is licensed by the ALBME and certified by the NCCPA. She enjoys working with patients of all ages and loves Family Medicine because of the long-lasting relationships that are made with patients. When she is not working, Alex enjoys traveling with her husband, reading, spending time with friends and family, and watching football and basketball. 
          </>}        
          />

          <OneClinicalTeam
          Title1="Katherine Koshofer"
          Title2="- PA"
          Title3="Board Certified"
          Title4={<>
          <div className="">
          Board Certified, NCCPA <br />
          Joined MPC in 2022 
          </div>          
          </>}
          BackDrop1="#staticBackdrop3"
          BackDrop2="staticBackdrop3"
          ProfileImg={Img4}
          Para={<>          
          
          Katherine Koshofer grew up in Oxford, MS. She received her Bachelor's Degree in Biology from Mississippi College in 2001. After exploring various options of how her passion for teaching and learning could be utilized in the medical field, she attended Physician Assistant school at Emory University, where she graduated with high honors in 2006. Katie has practiced Family Medicine for over 11 years in the greater Atlanta area, and now in Madison since 2018. She loves Family Medicine because of the relationships she’s able to develop with her patients. Katie believes that the choices we make every day impact our health, and she enjoys helping patients learn how to become their own health advocates. Katie's family moved to Madison from Atlanta to establish roots in a tight-knit community. When she's not working, Katie is spending time with her husband and their 3 children. 
          </>}         
          />

          <OneClinicalTeam
          Title1="Kelly Baugher"
          Title2="- FNP"
          Title3="Board Certified"
          Title4={
            <>
          <div className="">
          Board Certified, Family Nurse Practitioner <br />
          Joined MPC in 2019 
          </div>            
            </>
          }
          BackDrop1="#staticBackdrop4"
          BackDrop2="staticBackdrop4"
          ProfileImg={Img5}
          Para={<>

          Kelly Clark Baugher was born in Huntsville, AL and attended East Limestone High School. Her first bachelor's degree was in Communication Arts, and she became a published fiction novelist at the age of 25. She has always had an interest in the medical field, so she returned to college to attend nursing school. She received a Bachelor of Science in Nursing from the University of Alabama in Huntsville and worked as a NICU RN at Huntsville Hospital. She enjoyed being a member of the small baby team there, caring for extremely premature infants. She later received a Master of Science in Nursing from the University of Alabama in Huntsville and is a certified Family Nurse Practitioner. She has experience in urgent care, adult primary care, and pediatrics. Kelly enjoys working with patients of all ages and takes great pride in developing strong provider-patient relationships.  
          <div className="py-2" />
          Kelly has become MPC’s leader in ‘Quality’ scores, addressing patient HEDIS measures, and being a liaison with our ACO and Payer groups. Her commitment to elevating the ‘Quality’ of care for both the Practice and the Patient has been integral in navigating the complexities of excelling in our commitment to provide exceptional medical care while maintaining Payer regulations. 
          <div className="py-2" />
          Kelly currently leads MPS’s Chronic Care Management program, where she practices evidence-based medicine and delivers exceptional care to our Medicare population. 
          <div className="py-2" />
          In her free time, Kelly enjoys traveling with her family (especially to Costa Rica). She also enjoys reading, writing, photography, running, and volunteering. 
          </>}         
          />

          <OneClinicalTeam
          Title1="Kristy Williams"
          Title2="- FNP"
          Title3="Board Certified"
          Title4={<>
          <div className="">
          Board Certified, Family Nurse Practitioner <br />
          Joined MPC in 2022 
          </div>               
          </>}
          BackDrop1="#staticBackdrop5"
          BackDrop2="staticBackdrop5"
          ProfileImg={Img6}
          Para={<>     

          Kristy Williams was born and raised in Florence, AL where she grew up in the small community of Central Heights. She attended The University of North Alabama in Florence where she received her Bachelor of Science in Nursing degree. After graduation, Kristy moved to Birmingham to work at UAB Hospital where she gained experience in the infectious disease and hospitalist units. It was there that her passion for learning grew, and she decided to pursue graduate studies and become a Nurse Practitioner. She then moved back to the North Alabama area to attend the University of Alabama in Huntsville. While attending graduate school she worked as an RN at Huntsville Hospital’s orthopedic units. Kristy graduated from UAH with a Master of Science in Nursing degree, and is a Board Certified Family Nurse Practitioner. She has experience in urgent care and family practice, caring for patients ranging from pediatrics to geriatrics. Kristy finds joy and fulfillment in caring for her patients' healthcare needs, and serving our patients at Madison Primary Care. When she is not working, Kristy greatly enjoys spending time with her husband and children. She enjoys watching football, basketball, baseball, and hockey. Kristy’s favorite place to be is the beach. 
          </>}       
          />          

          <OneClinicalTeam
          Title1="Fakhra Ahmad-Bodla"
          Title2="- FNP"
          Title3="Board Certified"
          Title4={
            <>
          <div className="">
          Board Certified, Family Nurse Practitioner <br />
          Joined MPC in 2011 
          </div>              
            </>
          }
          BackDrop1="#staticBackdrop6"
          BackDrop2="staticBackdrop6"
          ProfileImg={Img7}
          Para={<>

          Fakhra Ahmad is a native of Pasadena, California where she’s lived all her life. She obtained her Bachelors in Nursing from the University of Southern California. 
          <div className="py-2" />
          Fakhra’s nursing career was focused in maternal-child health where she worked primarily as a Pediatric nurse. Fakhra furthered her education at the University of California, Los Angeles where she obtained her Master’s in Nursing and her certification as a Family Nurse Practitioner in 2003. 
          <div className="py-2" />
          Fakhra received in-depth experience in the areas of urgent care, adult medicine, pediatrics, and gynecology which has made her a competent and compassionate provider. She looks forward to being a partner in her patient’s health care decisions and strongly believes in health promotion activities. In her free time, Fakhra enjoys spending time with her husband and children. 
          </>}        
          />
          
          <OneClinicalTeam
          Title1="Erin Lee"
          Title2="- FNP"
          Title3="Board Certified"
          Title4={
            <>
          <div className="">
          Board Certified, Family Nurse Practitioner <br />
          Joined MPC in 2024 
          </div>                 
            </>
          }
          BackDrop1="#staticBackdrop11"
          BackDrop2="staticBackdrop11"
          ProfileImg={Img12}
          Para={<>
         
          A native of Brilliant, Alabama, Erin Lee graduated from the Mississippi University for Women (MUW) with her Bachelor of Science in Nursing degree in 2017. She spent the next five years working on a step-down telemetry floor at Baptist Memorial Hospital-Golden Triangle located in Columbus, MS while also pursuing her Master of Science in Nursing at the University of Alabama at Birmingham. Since becoming a family nurse practitioner, she has worked in a primary care setting focused on both acute and chronic care patients.
          <div className="py-2" />
          In her spare time, Erin enjoys traveling, reading, caring for her dogs, and spending time with her family.  
          </>}          
          />

          <OneClinicalTeam
          Title1="Christina Paasche"
          Title2="- PA"
          Title3="Board Certified"
          Title4={<>
          <div className="">
          Board Certified, NCCPA <br />
          Joined MPC in 2024 
          </div>                       
          </>}
          BackDrop1="#staticBackdrop12"
          BackDrop2="staticBackdrop12"
          ProfileImg={Img14}
          Para={<>

           My name is Christina Paasche. I am a Physician Assistant trained in Family Practice and I studied at the Medical College of Ohio (now The University of Toledo). I have over 20 years of clinical experience, mainly in pediatric care. I have a special love for Pediatrics and am excited to bring my passion and experience to Madison Primary Care. I look forward to joining the MPC Team and serving our Pediatric Patient Population. I am versatile with children of all ages, and experienced in wellness care, pediatric illnesses, dermatology, and behavioral health. In my free time, I am a mom to three boys and enjoy outdoor adventures with them.  
          </>}         
          />

          <OneClinicalTeam
          Title1="Anna Lueker"
          Title2="- AGNP"
          Title3="Board Certified"
          Title4={<>
          <div className="">
          Board Certified, Adult-Gerontology Primary Care Nurse Practitioner <br />
          Joined MPC in 2024 
          </div>           
          </>}
          BackDrop1="#staticBackdrop13"
          BackDrop2="staticBackdrop13"
          ProfileImg={Img13}
          Para={<>
           
          Anna Lueker is from Arab, AL and attended Arab High School followed by the University of Alabama in Tuscaloosa where she received her Bachelor of Science in Nursing. After graduation, Anna worked at UAB Hospital’s Critical Care Unit for four years. During this time, she pursued her Master of Science in Nursing and graduated from the University of Alabama in Birmingham as an Adult-Gerontology Primary Care Nurse Practitioner. Anna’s love for primary care is rooted in the relationships created with her patients and she looks forward to creating these relationships at Madison Primary Care. When she is not working, Anna enjoys traveling, attending concerts, and spending time with her husband!
          </>}          
          />                    
          
          <div className='fw-600 lh-sm text-center text-ss-primary fs-ss-30 pt-4'>
          MPC Management  
          </div>

          <OneClinicalTeam
          Title1="Mackenzie Romero"
          Title2=""
          Title3={<> <div className="text-ss-primary"> Office Manager </div> </>}
          Title4={<>
          <div className="">
          MPC, Office Manager  
          </div>          
          </>}
          BackDrop1="#staticBackdrop10"
          BackDrop2="staticBackdrop10"  
          ProfileImg={Img11}       
          Para={<>                  
          Born in Tennessee and raised in North Alabama, Mackenzie Romero is an accomplished Medical Office Manager who began her career in 2019 after attending Calhoun Community College. It was during this time that she discovered her passion for problem-solving and project management, which has become a cornerstone of her professional journey. 
          <div className="py-2" />
          Mackenzie is happily married to her high school sweetheart, and they enjoy spending quality time with family and friends. In her free time, she loves to travel and attend concerts.  
          </>} 
          />

          <OneClinicalTeam
          Title1={<> Alexis Chichipa </>}
          Title2=""
          Title3={<> <div className="text-ss-primary"> Front Office Lead </div> </>}
          Title4="Front Office Lead"
          BackDrop1="#staticBackdrop9"
          BackDrop2="staticBackdrop9"  
          ProfileImg={Img10}        
          />
          
          <OneClinicalTeam
          Title1="Melissa Montalvo"
          Title2="- MA"
          Title3="Clinical Lead"
          Title4="Clinical Lead"
          BackDrop1="#staticBackdrop8"
          BackDrop2="staticBackdrop8"    
          ProfileImg={Img9}      
          />

          <OneClinicalTeam
          Title1="Shamira Smith"
          Title2="- MA, BS"
          Title3="Billing Manager"
          Title4="Billing Manager"
          BackDrop1="#staticBackdrop7"
          BackDrop2="staticBackdrop7"  
          ProfileImg={Img10}        
          />

          </div>        
    </section>

    <section className='pt-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
            <img className='w-logo' src={Hospital} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-700 lh-sm'>
            Our Services
            </div>
            {/* <div className='pt-2'/> */}
            <div className='fs-ss-18 fw-500 lh-para'>
            Premium Health, Exceptional Care 
            </div>
          </div>

          <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">

<SingleService
Image={Icon8}
Name="Primary Care"
Link_To="/primary-care"
/>  

<SingleService
Image={Icon2}
Name="Immunizations"
Link_To="/immunizations"
/>

<SingleService
Image={Icon3}
Name={<> Laboratory <br /> Services </>}
Link_To="/laboratory-services"
/>        


<SingleService
Image={Icon5}
Name="Preventive Care"
Link_To="/preventive-care"
/> 

<SingleService
Image={Icon1}
Name="Diabetes Care"
Link_To="/diabetes-care"
/>

<div className="d-block d-lg-none col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
<Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
<img className='w-services' src={Icon10} alt="Pediatric Care" />
<div className='fw-500 pt-4 fs-ss-16 lh-sm'>
Pediatric Care
</div>
</Link>
</div> 

</div>
<div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
<SingleService
Image={Icon6}
Name="Weight Loss Management"
Link_To="/weight-loss-management"
/>

<SingleService
Image={Icon7}
Name="Medicare Chronic Care Management"
Link_To="/medicare-ccm"
/>

<SingleService
Image={Icon8}
Name="Sick Visits & Urgent Care"
Link_To="/sick-visits-and-urgent-care"
/>                 

<SingleService
Image={Icon4}
Name="Minor Skin Surgeries"
Link_To="/minor-skin-surgeries"
/>

<div className="d-none d-lg-block col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5">
<Link className='text-decoration-none text-ss-secondary' to="/pediatric-care">
<img className='w-services' src={Icon10} alt="Pediatric Care" />
<div className='fw-500 pt-4 fs-ss-16 lh-sm'>
Pediatric Care
</div>
</Link>
</div>  

</div>      
    </section>

    <section className='pt-5 '/>

    <Footer/>
    </>
  );
}

export default ClinicalTeam;
