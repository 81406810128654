import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/home/HomeBanner1.webp';
import Logo from '../assets/images/home/Logo2.webp';
import Hospital from '../assets/images/home/Hospital.webp';
import {Link} from "react-router-dom";
import SingleService2 from '../components/common/SingleService2';
import Icon1 from '../assets/images/ourservices/Icon1.webp';
import Icon2 from '../assets/images/ourservices/Icon2.webp';
import Icon3 from '../assets/images/ourservices/Icon3.webp';
import Icon4 from '../assets/images/ourservices/Icon4.webp';
import Icon5 from '../assets/images/ourservices/Icon5.webp';
import Icon6 from '../assets/images/ourservices/Icon6.webp';
import Icon7 from '../assets/images/ourservices/Icon7.webp';
import Icon8 from '../assets/images/ourservices/Icon8.webp';
import Icon9 from '../assets/images/ourservices/Icon9.webp';
import Icon10 from '../assets/images/ourservices/Icon10.webp';
import Icon11 from '../assets/images/ourservices/10.webp';
import Icon12 from '../assets/images/ourservices/11.webp';
import Icon13 from '../assets/images/ourservices/12.webp';
import Img1 from '../assets/images/ourservices/Img1.webp';
import MiddleBanner from '../components/common/MiddleBanner';

const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Our Services - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
           Our Services
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Quality Care in North Alabama 
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <img className='w-logo' src={Logo} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm '>
            Our Services  <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
              At Madison Primary Care, we are committed to providing comprehensive healthcare services designed to meet the diverse needs of your family. The MPC Team is equipped to manage your total care, including chronic disease management, urgent care needs, health care maintenance, and preventative care. Explore our range of quality services tailored to your well-being.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 '/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
          <div className='text-center'>
            <img className='w-logo' src={Hospital} alt="" />
            <div className='pt-2'/>
            <div className='fs-ss-30 fw-600 fw-700 lh-sm'>
            Our Services
            </div>
            {/* <div className='pt-2'/> */}
            <div className='fs-ss-18 fw-500 lh-para'>
            Premium Health, Exceptional Care 
            </div>
          </div>
      <div className="row justify-content-evenly text-center pt-4 pt-lg-5 gy-4">
        <SingleService2
        Image={Icon1}
        Name="Diabetes Care"
        Link_To="/diabetes-care"
        Para="Diabetes care involves monitoring blood sugar levels, medication management, dietary adjustments, and regular exercise to optimize health and prevent complications."
        />

        <SingleService2
        Image={Icon2}
        Name="Immunizations"
        Link_To="/immunizations"
        Para="Protect your family with various vaccinations and immunizations our experienced healthcare professionals administer. "
        />

        <SingleService2
        Image={Icon3}
        Name="Laboratory Services"
        Link_To="/laboratory-services"
        Para="We offer convenient in-house lab services, ensuring prompt and accurate test results for efficient diagnosis and treatment planning. "
        />        

        <SingleService2
        Image={Icon4}
        Name="Minor Skin Surgeries"
        Link_To="/minor-skin-surgeries"
        Para="Our skilled medical team performs minor skin surgeries with precision and care, addressing various dermatological concerns. "
        />
        
        <SingleService2
        Image={Icon5}
        Name="Preventive Care"
        Link_To="/preventive-care"
        Para="Empowering you with knowledge, our health education and promotion initiatives aim to enhance your understanding of preventive healthcare. "
        /> 
        
        <SingleService2
        Image={Icon10}
        Name="Pediatric Care"
        Link_To="/pediatric-care"
        Para="Pediatric care involves medical attention and support tailored specifically for infants, children, and adolescents."
        />         

        <SingleService2
        Image={Icon6}
        Name="Weight Loss Management"
        Link_To="/weight-loss-management"
        Para="Weight loss management encompasses strategies aimed at achieving and maintaining a healthy body weight through a combination of dietary changes, physical activity, behavior modification, and support. "
        />

        <SingleService2
        Image={Icon7}
        Name="EKG"
        Link_To="/ekg"
        Para="We conduct EKG and lung function tests using advanced diagnostic tools to assess cardiac and respiratory health. "
        />

        <SingleService2
        Image={Icon8}
        Name="Sick Visits & Urgent Care"
        Link_To="/sick-visits-and-urgent-care"
        Para="Receive prompt attention and care for unexpected illnesses or urgent medical needs at our clinic. "
        />     

        <SingleService2
        Image={Icon13}
        Name="Primary Care"
        Link_To="/primary-care"
        Para="Your first point of contact in healthcare, offering services for overall health, disease prevention, and managing chronic conditions."
        />    

        <SingleService2
        Image={Icon11}
        Name="Chronic Care Management"
        Link_To="/medicare-ccm"
        Para="We offer Medicare patients personalized care coordination and support to improve health outcomes, and prevent adverse health events."
        />    

        <SingleService2
        Image={Icon12}
        Name="Medicare ACO"
        Link_To="/medicare-aco"
        Para="Delivering coordinated, high-quality care, with the goal of improving health outcomes and reducing costs."
        />    

      </div>
    </section>
    
    <section className='pt-5'/>

    <MiddleBanner/>

    <section className='pt-5 '/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">

      <div className="col-11 text-ss-secondary">
          <div className=''>
            <div className='fs-ss-26 fw-600 lh-sm text-ss-primary'>
            Children
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Yearly school and sports physicals, sick visits, school problems, behavioral issues, and vaccines.
            </div>
            </div>
            </div>

            <div className="col-11 text-ss-secondary">
          <div className=''>
            <div className='fs-ss-26 fw-600 lh-sm text-ss-primary'>
            Teenagers
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Healthy lifestyle and risky behavior education, sports and school physicals, sick visits, and skin care.
            </div>
            </div>
            </div>

            <div className="col-11 text-ss-secondary">
          <div className=''>
            <div className='fs-ss-26 fw-600 lh-sm text-ss-primary'>
            Young Adult
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Wellness and preventive care, health promotion and screening, disease management, urgent care visits, gynecology, and nutritional counseling.
            </div>
            </div>
            </div>

            <div className="col-11 text-ss-secondary">
          <div className=''>
            <div className='fs-ss-26 fw-600 lh-sm text-ss-primary'>
            Older Adult
            </div>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para'>
            Compassionate care to manage healthy adults as well as those suffering from chronic medical conditions. Collaborative approach with specialists and family members, to provide comprehensive patient care. Commitment to involvement in medical care both in the clinic and in the hospital. 
            </div>
            </div>
            </div>

      </div>
    </section>

    <section className='pt-5 '/>

    <section id='website-responsive' className="container-lg text-center text-md-start text-ss-secondary">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-8 col-md-5 text-center px-lg-5 d-none d-md-block">
        <img className='w-100 px-md-5' src={Img1} alt="" />
        </div>
        <div className="col-11 col-md-6">
        <div className=''>
        <div className='fs-ss-35 fw-700 lh-sm'>
        Dedicated Care
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para text-start'>
        Madison Primary Care Clinic is your Family's Health Hub in Madison. Our HealthCare Professionals practice evidence based medicine, where sound medical research drives their medical decisions.
        </div>
        </div>
        </div>
        <div className="col-7 col-lg-5 text-center px-lg-5 d-block d-md-none">
        <img className='w-100 px-lg-5' src={Img1} alt="" />
        </div>
      </div>
      </section>

      <section className='pt-5 '/>

    <Footer/>
    </>
  );
}

export default OurServices;
