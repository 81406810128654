import React from 'react';
import {Link} from 'react-router-dom';

const SingleService2 = (props) => {
  return (
    <>
    <div className="col-serv2 text-start bg-grey shadow-ss-1 rounded-4 py-4 ps-4 pe-4">
      <Link className=' text-start text-decoration-none text-ss-secondary' to={props.Link_To}>
      <img className='w-services' src={props.Image} alt={props.Alt} />
      <div className='fw-600 pt-4 fs-ss-18 lh-sm'>
        {props.Name}
      </div>
      <div className='fw-400 py-2 fs-ss-13 lh-sm'>
        {props.Para}
      </div>
      <span className='text-decoration-none text-ss-primary fw-500 fs-ss-13' href={props.Link_To}> Read more </span>
      </Link>
    </div>
    </>
  )
}

export default SingleService2