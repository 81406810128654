import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/health/Banner.webp';
import ImgJoinUs from '../assets/images/health/ImgJoinUs.webp';
import Img1 from '../assets/images/health/Img1.webp';
import Img2 from '../assets/images/health/Img2.webp';
import Img3 from '../assets/images/health/Img3.webp';
import Img4 from '../assets/images/health/Img4.webp';
import Img5 from '../assets/images/health/Img5.webp';
import Img6 from '../assets/images/health/Img6.webp';
import Img7 from '../assets/images/health/Img7.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import { TelephoneFill } from 'react-bootstrap-icons';

const HealthLinks = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Health Links - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Health Links
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className='fs-ss-30 fw-600 lh-sm pb-5 text-center'>
      Health Links
      </div>
      <div className="row justify-content-evenly text-center gy-5">

      <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="https://familydoctor.org/">
          <img src={Img1} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          Family Doctor - Health Information
          </div>
      </a>    
      
      <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="http://www.uptodate.com/patients">
          <img src={Img2} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          Up To Date - Evidence Based Medicine, Patient Health Information
          </div>
      </a> 

            <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="https://www.cdc.gov/">
          <img src={Img3} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          Center for Disease Control - General Health & Vaccine Information
          </div>
      </a>    
      
      <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="http://www.americanheart.org/">
          <img src={Img4} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          American Heart Association - Heart Health
          </div>
      </a> 

            <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="https://www.acog.org/error/404?item=%2fpublications%2fpatient_education&user=default%5cAnonymous&site=AcogOrg">
          <img src={Img5} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          ACOG Patient Education Pamphlets - Women's Health Information
          </div>
      </a>    
      
      <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="https://www.cancer.org/cancer/index.html">
          <img src={Img6} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          American Cancer Society - Cancer Education & Prevention
          </div>
      </a>    

      <a className="col-9 col-lg-5 bg rounded-4 py-4 shadow-ss-1 text-decoration-none text-ss-secondary" href="https://www.healthychildren.org/English/Pages/default.aspx">
          <img src={Img7} alt="" className='w-50' />
          <div className="fs-ss-18 fw-600 pt-4 lh-sm px-4">
          HealthyChildren.org - Pediatric Healthy, Safety & Education
          </div>
      </a>    

      <div className="col-9 col-lg-5"/>  
                
      </div>
    </section>

        <section className="container-fluid bg-video">
        <section className='pt-5 mt-5'/>
      <section id='website-responsive' className="container-lg text-center text-lg-start text-ss-secondary">
      <div className="row justify-content-center pt-4 pt-lg-5 gy-4 align-items-center">
        <div className="col-8 col-lg-5 text-center px-lg-5">
        <img className='w-100 px-lg-5' src={ImgJoinUs} alt="" />
        </div>

        <div className="col-12 col-lg-6">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
        <div className='fs-ss-35 fw-700 lh-sm'>
          Join Us in Your <br />
        <span className='text-ss-primary'> Journey to Wellness </span>
        </div>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        At Madison Primary Care, we invite you to experience healthcare designed around you. Take the first step towards a healthier life today by scheduling a consultation with Dr. Bodla. Let us become your health partners, guiding you toward long-term wellness. 
        </div>
        <div className='pt-4'/>
        <div className='pt-2'/>        
        <div className=''>
            <a href="tel:+12567745524" className='text-decoration-none py-2 fw-500 btn-ss-primary border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
             <TelephoneFill className='me-1' /> 256-774-5524
              </a>
          </div>
        </div>
        </div>
      </div>
      </section>

      <section className='pt-5'/>
      </section>

    <Footer/>
    </>
  );
}

export default HealthLinks;
