import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/privacy/Banner.webp';
import {Link} from "react-router-dom";


const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
      <title>
      Privacy Policy - Madison Primary Care
      </title>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <div className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Privacy Policy
          </div>
          <div className='fw-400 lh-sm text-ss-green banner-title2'>
          Madison Primary Care
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>

    <section className='pt-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center">
        <div className="col-11 col-lg-11">

        <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Privacy Policy 
              </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              Madison Primary Care is committed to protecting your privacy and ensuring the confidentiality of your personal and health information. This Privacy Policy outlines how we collect, use, disclose, and protect our information in compliance with applicable laws and regulations.             
              </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Information Collection               
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              We collect personal and health information when you register as a patient or use our services. This information may include but is not limited to your name, contact details, medical history, insurance information, and any data collected during consultations or treatments.            
               </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Use of Information 
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              Your information is used to provide healthcare services, manage appointments, process payments, and ensure quality care. We may also use it for administrative purposes, such as improving our services, communicating with you, and complying with legal obligations.             
              </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Disclosure of Information               
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              We may disclose your information to authorized healthcare professionals in our clinic for treatment. In certain situations, we might share information with external entities, such as laboratories, specialists, or insurance providers, only as necessary for your care or as required by law.             </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Security Measures             
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              We employ robust security measures to safeguard your information from unauthorized access, disclosure, alteration, or destruction. We maintain physical, electronic, and procedural safeguards aligned with industry standards.         
              </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Patient Rights             
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              You have the right to access, amend, or request the deletion of your information. You can also request restrictions on how your information is used or disclosed, subject to legal or clinical considerations.           
              </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Third-Party Links             
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              Our website may contain links to third-party websites or resources. We are not responsible for these external sites' privacy practices or content. We encourage you to review their privacy policies before providing any personal information.              
              </div>
          </div>

          <div className='pb-4'>
            <div className='fs-ss-22 fw-600 fw-600 lh-sm'>
            Updates to Privacy Policy               
            </div>
              <div className='pt-1'/>
              <div className='fs-ss-16 fw-400 lh-para'>
              We reserve the right to update this Privacy Policy to reflect changes in our practices or regulatory requirements. Any modifications will be posted on our website. 
              
              By using our services or providing information to Madison Primary Care, you consent to collecting, using, and disclosing your information as described in this Privacy Policy.

              If you have any questions or concerns regarding this Privacy Policy or the handling of your information, please get in touch with us at <a href="tel:+12567745524" className='text-decoration-none py-2 fw-500 text-ss-primary' target='_blank'> 256-774-5524 </a>
              </div>
          </div>
          
        </div>
      </div>
    </section>

    <section className='pt-md-4'/>

    <Footer/>
    </>
  );
}

export default PrivacyPolicy;
