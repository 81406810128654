import React from 'react';
import {Link} from 'react-router-dom';

const SingleService = (props) => {
  return (
    <>
    <Link className="col-serv text-center bg-service py-2 shadow-ss-1 rounded-4 py-5 text-decoration-none text-ss-secondary" to={props.Link_To}>
      <img className='w-services' src={props.Image} alt={props.Alt} />
      <div className='fw-500 pt-4 fs-ss-16 lh-sm'>
        {props.Name}
      </div>
    </Link>
    </>
  )
}

export default SingleService